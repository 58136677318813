import React, { useEffect, useState } from "react"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

import Layout from "../components/layout/layout"
import Loading from "../components/loading"
import Redirecting from "../components/redirecting"
import axios from "axios"
import { navigate } from "gatsby"
import queryString from "query-string"

const CheckoutPageComponent = ({ location }) => {
	const { getIdTokenClaims } = useAuth0()
	const qs = queryString.parse(location.search)

	useEffect(() => {
		const productId = qs.productId
		const membershipProductId = qs.membershipProductId
		const createOrder = async productId => {
			try {
				const tokenClaims = await getIdTokenClaims()
				const response = await axios.post(
					`${process.env.GATSBY_API_BASE_URL}/order/${
						productId ? productId : membershipProductId
					}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${tokenClaims.__raw}`,
						},
					}
				)
				const orderId = response.data

				navigate(`/checkout?orderId=${orderId}`, {
					replace: true,
					state: { membership: membershipProductId ? true : false },
				})
			} catch (e) {
				// console.log(e.message)
			}
		}
		createOrder(productId)
	}, [])

	return (
		<Layout>
			<div className="text-center flex flex-col space-y-2 h-screen justify-center items-center mb-15">
				<div className="text-lg">Creating Order</div>
				<Loading />
			</div>
		</Layout>
	)
}

export default withAuthenticationRequired(CheckoutPageComponent, {
	onRedirecting: () => <Redirecting />,
})
